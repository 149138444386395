<template>
  <Page title="Anwesenheitskontrolle" color="info">
    <template slot="help">
      <Markdown :value="helpText" />

      <v-btn text block outlined color="primary" :to="{ name: 'Settings' }"
        >zu den Einstellungen</v-btn
      >
    </template>
    <template slot="extension">
      <v-tabs
        background-color="info darken-1"
        slider-color="white"
        :align-with-title="!$vuetify.breakpoint.xs"
      >
        <v-tab
          exact
          :to="{
            name: 'AttendanceChecksCurrent',
            params: { noredirection: true },
          }"
          ><v-icon left>mdi-arrow-left</v-icon
          ><template v-if="!$vuetify.breakpoint.xs">andere</template></v-tab
        >
        <v-spacer />
        <v-tab
          :to="{
            name: 'AttendanceCheckPanel',
          }"
          >Spiegel</v-tab
        >
        <v-tab
          :to="{
            name: 'AttendanceCheckList',
          }"
          >Liste</v-tab
        >
      </v-tabs>
    </template>
    <v-card>
      <v-system-bar v-if="data && data.course">
        <span v-if="$vuetify.breakpoint.xs">
          <DateValue :value="data.date" />,
          {{
            formatTimespan(data.date, data.startTime, data.date, data.endTime)
          }},
          {{ data.periods.length > 1 ? data.periods.length + " L" : "" }}
        </span>
        <span v-else>
          <DateValue :value="data.date" long />,
          {{
            formatTimespan(data.date, data.startTime, data.date, data.endTime)
          }},
          {{
            data.periods.length > 1
              ? data.periods.length + " Lektionen"
              : "eine Lektion"
          }}
        </span>
        <v-spacer />
        <span>{{ data.course.title }}</span>
      </v-system-bar>
      <v-card-subtitle>
        {{ data.studentItems.length }} Schüler*innen,
        {{ countDispensated }} dispensiert, {{ countAbsent }} abwesend
      </v-card-subtitle>

      <v-toolbar flat v-if="!$vuetify.breakpoint.xs">
        <div>
          <v-btn icon @click="zoomMinus" :disabled="zoom == zoomMin"
            ><v-icon>mdi-magnify-minus</v-icon></v-btn
          >
          <v-btn text disabled>Breite {{ zoom }}px</v-btn>

          <v-btn icon @click="zoomPlus" :disabled="zoom == zoomMax"
            ><v-icon>mdi-magnify-plus</v-icon></v-btn
          >
        </div>

        <v-btn-toggle
          v-model="groupFilter"
          v-if="groups.length > 0"
          multiple
          dense
        >
          <v-btn
            text
            v-for="group in groups"
            :value="group"
            :key="'group' + group"
            >{{ group }}</v-btn
          >
        </v-btn-toggle>

        <v-spacer />

        <v-btn
          outlined
          color="primary"
          :disabled="data.signed"
          @click="sign(true)"
          >alle anwesend</v-btn
        >
      </v-toolbar>

      <v-card-text v-else>
        <v-btn-toggle
          v-model="groupFilter"
          v-if="groups.length > 0"
          multiple
          dense
          class="mb-2"
        >
          <v-btn
            text
            v-for="group in groups"
            :value="group"
            :key="'group' + group"
            >{{ group }}</v-btn
          >
        </v-btn-toggle>
        <v-btn
          block
          outlined
          color="primary"
          :disabled="data.signed"
          @click="sign(true)"
          >alle anwesend</v-btn
        >
      </v-card-text>

      <v-card :loading="loading" flat class="ma-0 pa-0 pt-4">
        <router-view
          :items="itemsFiltered"
          :zoom="zoom"
          v-on:toggle="toggle"
        ></router-view>
      </v-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :block="$vuetify.breakpoint.xs"
          outlined
          color="error"
          :disabled="data.signed"
          @click="sign(false)"
          class="mr-2"
          >nicht durchführbar</v-btn
        >
      </v-card-actions>
    </v-card>
  </Page>
</template>

<script>
import DateValue from "common/components/DateValue.vue";
import { comparePeople } from "common/utils/people.js";
import { formatTimespan } from "common/utils/date.js";

// Clamp number between two values with the following line:
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const helpText = `
Die Seite bietet zwei Ansichten

- In der **Spiegel-Ansicht** werden Doppel- und Mehrfachlektionen zusammen genommen und die Schüler*innen mit Foto dargestellt
- In der **Listen-Ansicht** können auch bei Mehrfachlektionen diese einzeln erfasst werden.

Sortierung und Namensdarstellung der Schüler*innen können global in den Einstellungen gewählt werden.
`;

export default {
  components: { DateValue },
  props: ["course", "date", "period"],
  data() {
    return {
      helpText,
      loading: false,
      zoomMin: 100,
      zoomMax: 500,
      data: {
        studentItems: [],
      },
      zoom: 140,
      groupFilter: [],
    };
  },
  computed: {
    countDispensated() {
      return this.data.studentItems.filter((item) => item.dispensated).length;
    },
    countAbsent() {
      return this.data.studentItems.filter((item) => !item.present).length;
    },
    groups() {
      const result = new Set();
      for (let item of this.data.studentItems) {
        for (let group of item.studentGroups) {
          result.add(group);
        }
      }
      return [...result].sort();
    },
    itemsFiltered() {
      if (this.groupFilter.length == 0) {
        return this.data.studentItems;
      }
      return this.data.studentItems.filter((el) =>
        el.studentGroups.some((value) => this.groupFilter.includes(value))
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.data = await this.apiList({
        resource: "absence/attendancecheck",
        query: `course=${this.course}&date=${this.date}&period=${this.period}`,
      });

      this.data.studentItems.sort((a, b) =>
        comparePeople(a.student, b.student)
      );

      this.loading = false;
    },
    formatTimespan,
    async sign(value) {
      if (!value) {
        if (
          !(await this.$root.confirm({
            message:
              "Soll diese Kontrolle als nicht durchführbar markiert werden?",
          }))
        ) {
          return;
        }
      }
      const data = {
        course: this.course,
        date: this.date,
        period: this.period,
        site: this.site,
        checked: value,
      };
      await this.apiPost({ resource: "absence/attendancecheck", data: data });
      this.$router.push({ name: "AttendanceChecks" });
    },
    async toggle(item, periodItems, absent) {
      if (!this.data.signed) {
        const data = {
          course: this.course,
          date: this.date,
          period: this.period,
          checked: true,
        };
        await this.apiPost({ resource: "absence/attendancecheck", data: data });
      }
      const data = {
        course: this.data.course,
        student: item.student,
        site: this.data.site,
        period: periodItems[0].period,
        endPeriod: periodItems[periodItems.length - 1].period,
        absent: absent,
        date: this.data.date,
      };
      await this.apiPost({ resource: "absence/absence", data: data });

      this.loading = true;
      const update = await this.apiList({
        resource: "absence/attendancecheck",
        query: `course=${this.course}&date=${this.date}&period=${this.period}`,
      });
      const indexUpdate = update.studentItems.findIndex(
        (el) => el.student.id === item.student.id
      );
      const index = this.data.studentItems.findIndex(
        (el) => el.student.id === item.student.id
      );
      this.data.studentItems.splice(index, 1, update.studentItems[indexUpdate]);
      this.data.active = update.active;
      this.data.signed = update.signed;
      this.loading = false;
    },
    zoomMinus() {
      this.zoom = clamp(this.zoom - 10, this.zoomMin, this.zoomMax);
      localStorage.setItem("AttendanceCheckZoom", this.zoom);
    },
    zoomPlus() {
      this.zoom = clamp(this.zoom + 10, this.zoomMin, this.zoomMax);
      localStorage.setItem("AttendanceCheckZoom", this.zoom);
    },
  },
  created() {
    this.fetchData();
    if (localStorage.getItem("AttendanceCheckZoom")) {
      this.zoom = clamp(
        parseInt(localStorage.getItem("AttendanceCheckZoom")),
        this.zoomMin,
        this.zoomMax
      );
    }
  },
};
</script>
